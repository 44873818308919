body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.hero.banner {
  background: black url(/static/media/home.2b1b4d63.jpg) center / cover;
}

.grow { 
  -webkit-transition: all .2s ease-in-out; 
  transition: all .2s ease-in-out;
}

.grow:hover { 
  -webkit-transform: scale(1.05); 
          transform: scale(1.05); 
}
.grow { 
  -webkit-transition: all .2s ease-in-out; 
  transition: all .2s ease-in-out;
}

.grow:hover { 
  -webkit-transform: scale(1.05); 
          transform: scale(1.05); 
}
.is-scrollable {
  overflow: scroll;
  height: 400px;
  width: 140%;
}

.table {
  width: 100%;
}

td.ask {
  color: rgb(249, 103, 45);
}

td.bid {
  color: rgb(36, 156, 41);
}

.tabs.is-toggle li.is-active a {
  background-color: #209cee !important;
  border-color: #dbdbdb !important
}



.grow {
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.grow:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

p.fair-value {
  color: rgb(36, 156, 41);
}

