.hero.banner {
  background: black url('../../../assets/images/home.jpg') center / cover;
}

.grow { 
  transition: all .2s ease-in-out;
}

.grow:hover { 
  transform: scale(1.05); 
}