.grow {
  transition: all .2s ease-in-out;
}

.grow:hover {
  transform: scale(1.05);
}

p.fair-value {
  color: rgb(36, 156, 41);
}
