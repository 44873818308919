.is-scrollable {
  overflow: scroll;
  height: 400px;
  width: 140%;
}

.table {
  width: 100%;
}

td.ask {
  color: rgb(249, 103, 45);
}

td.bid {
  color: rgb(36, 156, 41);
}
